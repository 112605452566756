import React, { Component } from "react";
import "./Header.css";
import { Image } from "semantic-ui-react";
import BiotoolsLogo from "../src/biotools-brand-small.png";
import headerLogoLightBackground from "../src/lattice-logo-2019-newText-lightBackground.svg";

export class Header extends Component {
  state = {};

  render() {
    return (
      <div className="app-header">
        <div className="header-logos">
          <Image
            alt="Lattice logo"
            src={headerLogoLightBackground}
            className="ui small middle aligned image"
            as="a"
            href="https://latticeautomation.com/"
          />
          <div className="vertical-divider" />
          <Image
            alt="Biotools logo"
            src={BiotoolsLogo}
            className="ui small middle aligned image"
          />
        </div>
      </div>
    );
  }
}
